@font-face {
    font-family: 'OpenSans-Light';
    src: url('./OpenSans-Light.ttf') format('truetype'), ;
}

@font-face {
    font-family: 'OpenSans-Regular';
    src: url('./OpenSans-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'OpenSans-Medium';
    src: url('./OpenSans-Medium.ttf') format('truetype'), ;
}
