.header {
    width: 100%;
    position: fixed;
    top: 0px;
    z-index: 99999;

    .container {
        width: 100%;
        max-width: 1340px;
        margin-inline: auto;
        height: 112px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 20px;
        background-color: #ffffff;

        .left {
            padding-inline: 52px;

            img {
                width: 180px;
            }
        }

        .right {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-inline: 26.5px;

            @media screen and (max-width: 1200px) {
                display: none;
            }

            .nav {
                display: flex;
                gap: 30px;
                margin-right: 72.5px;

                span {
                    display: inline-block;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 15px;
                    line-height: 100%;
                    min-width: 80px;
                }
            }

            .launchBtn {
                margin-left: 30px;
                background-image: linear-gradient(#0d64e9, #31a8fc);
                border-radius: 10px;
                font-family: 'Nexa';
                font-style: normal;
                font-weight: 700;
                font-size: 15px;
                line-height: 153%;
                color: #ffffff;
                padding: 6.5px 26.5px;

                &:hover {
                    color: #000000;
                    transform: scale(1.1);
                }
            }
        }
    }

    .menuBtn {
        margin-right: 20px;
        display: none;

        @media screen and (max-width: 1200px) {
            display: unset;
        }
    }
}
