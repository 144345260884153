.home {
    padding-top: 190px;

    .banner {
        .title {
            max-width: 1200px;
            margin-inline: auto;
            padding: 2% 10%;
            font-family: 'Nexa';
            font-style: normal;
            font-weight: bold;
            font-size: 72px;
            line-height: 111%;
            text-align: center;

            img {
                height: 52px;
            }

            @media screen and (max-width: 900px) {
                font-size: 42px;

                img {
                    height: 30px;
                }
            }
        }

        .desc {
            margin-top: 24px;
            max-width: 973px;
            margin-inline: auto;
            padding-inline: 5%;
            font-weight: 400;
            font-size: 24px;
            text-align: center;
        }

        .launch {
            max-width: 1240px;
            margin-inline: auto;
            margin-top: 80px;
            padding-inline: 90px;
            padding-bottom: 111px;
            text-align: center;
            position: relative;

            @media screen and (max-width: 1200px) {
                padding-inline: 0;
            }

            .launchBtn {
                width: fit-content;
                margin-inline: auto;
                background-image: url('../../assets/img/menu_bg.svg');
                color: white;
                background-size: 100% 100%;
                padding: 37px 67px;
                font-family: 'Nexa';
                font-style: normal;
                font-weight: 700;
                font-size: 24px;
                line-height: 24px;
                z-index: 999;
                position: inherit;
                cursor: pointer;

                &:hover {
                    color: black;
                    transform: scale(1.1);
                }

                @media screen and (max-width: 1200px) {
                    padding: 21px 38px;
                    font-size: 18px;
                    line-height: 18px;
                }
            }

            .img_1 {
                margin-top: -100px;
                margin-inline: auto;
                width: 100%;
                z-index: 990;

                @media screen and (max-width: 1200px) {
                    margin-top: -60px;
                }
            }

            .img_2 {
                margin-top: -280px;
                margin-inline: auto;
                display: block;

                @media screen and (max-width: 1200px) {
                    margin-top: -180px;
                }

                @media screen and (max-width: 900px) {
                    display: none;
                }
            }

            .img_2_mobile {
                margin-top: -90px;
                margin-inline: auto;
                display: none;

                @media screen and (max-width: 900px) {
                    display: block;
                    margin-top: -15vw;
                }
            }

            .img_3 {
                position: absolute;
                top: 80px;
                right: 220px;

                @media screen and (max-width: 1200px) {
                    top: 18vw;
                    width: min(50vw, 252px);
                    right: min(12vw, 300px);
                }
            }
        }
    }

    .features {
        max-width: 1240px;
        margin-inline: auto;
        background: #F7F9FC;
        border-radius: 50px;
        padding: 180px 80px 60px;
        position: relative;
        overflow: hidden;
        z-index: 9990;

        &::before {
            content: '';
            position: absolute;
            bottom: -40px;
            right: -120px;
            width: 690px;
            height: 890px;
            background-image: url('../../assets/img/subtract.png');
            transform: rotate(15deg);
            z-index: -1;
        }

        @media screen and (max-width: 1200px) {
            padding: 160px 20px 30px;
            margin-inline: 5%;
        }

        .title {
            font-family: 'Nexa';
            font-style: normal;
            font-weight: 800;
            font-size: 50px;
            line-height: 100%;
            z-index: 9990;

            @media screen and (max-width: 900px) {
                font-size: 40px;
            }
        }

        .desc {
            margin-top: 30px;
            font-weight: 400;
            font-size: 24px;
            z-index: 9990;
        }

        .feature {
            margin-top: 4%;
            padding-inline: 20px;
            z-index: 9990;

            @media screen and (max-width: 900px) {
                padding-inline: 0;
            }

            .tabs {
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                background-color: #0453e51a;
                border-radius: 50px;
                padding: 2px;
                font-family: 'Nexa';
                font-weight: 700;
                font-size: 15px;
                line-height: 357%;

                @media screen and (max-width: 900px) {
                    grid-auto-flow: column;
                    grid-template-columns: unset;
                    grid-template-rows: repeat(5, 1fr);
                    border-radius: 20px;
                }

                .tab {
                    padding: 8px 3px;
                    text-align: center;
                    cursor: pointer;

                    @media screen and (max-width: 900px) {
                        padding: 0;
                    }
                }

                .tabActive {
                    @extend .tab;
                    background-color: #fff;
                    filter: drop-shadow(0px 4px 20px #0000000d);
                    border-radius: 50px;

                    @media screen and (max-width: 900px) {
                        border-radius: 20px;
                    }
                }
            }

            .content {
                margin-top: 4%;
                display: grid;
                grid-template-columns: 1fr 1fr;

                @media screen and (max-width: 900px) {
                    padding-inline: 18px;
                    display: block;
                }

                .left {

                    .title {
                        font-family: 'Nexa';
                        font-style: normal;
                        font-weight: 800;
                        font-size: 32px;
                        line-height: 50px;

                        @media screen and (max-width: 900px) {
                            font-size: 28px;
                        }
                    }

                    .desc {
                        @media screen and (max-width: 900px) {
                            font-size: 20px;
                            margin-bottom: 1rem;
                        }
                    }
                }

                .right {
                    position: relative;
                    margin-inline: auto;
                    text-align: center;

                    img {
                        margin-bottom: -5%;
                        max-width: 100%;
                        display: block;

                        @media screen and (max-width: 900px) {
                            display: block;
                            margin-inline: auto;
                        }
                    }

                    .chat_2 {
                        position: absolute;
                        right: -120px;
                        top: 60px;

                        @media screen and (max-width: 900px) {
                            position: static;
                        }
                    }
                }
            }
        }
    }

    .blog {
        max-width: 1080px;
        margin-inline: auto;
        margin-top: 200px;
        display: grid;
        grid-template-columns: 1fr auto 1fr;

        @media screen and (max-width: 1200px) {
            margin-top: 100px;
            display: block;
            padding: 20px;
        }

        .left {
            text-align: left;

            .title {
                font-family: 'Nexa';
                font-weight: 800;
                font-size: 50px;
                line-height: 100%;

                @media screen and (max-width: 900px) {
                    font-size: 40px;
                }
            }

            .desc {
                margin-top: 35px;
                margin-bottom: 30px;
                font-weight: 400;
                font-size: 24px;
                padding-right: 6%;

                @media screen and (max-width: 1200px) {
                    padding: 0;
                }
            }

            img {
                display: block;
                max-width: 100%;

                @media screen and (max-width: 1200px) {
                    margin-inline: auto;
                }
            }
        }

        .line {
            width: 1px;
            height: 100%;
            background-color: #0000001a;

            @media screen and (max-width: 1200px) {
                width: 100%;
                height: 1px;
                margin-top: 50px;
                margin-bottom: 50px;
            }
        }

        .right {
            text-align: right;

            @media screen and (max-width: 1200px) {
                text-align: left;
            }

            .title {
                font-family: 'Nexa';
                font-weight: 800;
                font-size: 50px;
                line-height: 50px;

                @media screen and (max-width: 900px) {
                    font-size: 40px;
                }
            }

            .desc {
                margin-top: 35px;
                margin-bottom: 30px;
                font-weight: 400;
                font-size: 24px;
                padding-left: 6%;

                @media screen and (max-width: 1200px) {
                    padding: 0;
                }
            }

            img {
                display: block;
                margin-left: auto;
                max-width: 100%;

                @media screen and (max-width: 1200px) {
                    margin-inline: auto;
                }
            }
        }
    }

    .read {
        max-width: 1240px;
        margin-inline: auto;
        margin-top: 120px;
        background: #F7F9FC;
        border-radius: 50px;
        padding: 185px 80px 30px;
        text-align: center;
        position: relative;
        overflow: hidden;

        &::before {
            content: '';
            position: absolute;
            bottom: -40px;
            right: -120px;
            width: 690px;
            height: 890px;
            background-image: url('../../assets/img/subtract.png');
            transform: rotate(15deg);
        }

        @media screen and (max-width: 1200px) {
            padding: 160px 20px 30px;
            margin-inline: 5%;
        }

        .title {
            font-family: 'Nexa';
            font-style: normal;
            font-weight: 800;
            font-size: 50px;
            line-height: 100%;

            @media screen and (max-width: 900px) {
                font-size: 40px;
            }
        }

        .desc {
            margin-top: 30px;
            font-weight: 400;
            font-size: 24px;
            line-height: 29px;
        }

        .content {
            margin-top: 10px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 10px;

            @media screen and (max-width: 900px) {
                display: block;
            }

            img {
                width: 100%;
            }
        }
    }

    .testimonials {
        max-width: 1080px;
        margin-inline: auto;
        padding-top: 200px;

        @media screen and (max-width: 1200px) {
            padding: 160px 20px 20px 20px;
        }

        .title {
            font-family: 'Nexa';
            font-weight: 800;
            font-size: 50px;
            line-height: 100%;

            @media screen and (max-width: 900px) {
                font-size: 40px;
            }
        }

        .desc {
            margin-top: 35px;
            margin-bottom: 60px;
            font-weight: 400;
            font-size: 24px;
            padding-right: 6%;
        }

        .content {
            display: grid;
            grid-template-columns: repeat(2, 1fr);

            @media screen and (max-width: 900px) {
                display: block;
            }

            .left {

                .person {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    gap: 22px;
                    margin-bottom: 30px;
                    background-color: #ffffff;

                    .circle {
                        width: 22px;
                        height: 22px;
                        background-color: #e9e9e9;
                        border-radius: 50%;
                    }

                    .activeCircle {
                        @extend .circle;
                        background-color: #0453E5;
                    }

                    .feature {
                        width: 100%;
                        padding: 15px;
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        border-radius: 20px;
                        cursor: pointer;

                        .name {
                            font-size: 18px;
                            font-family: Arial, Helvetica, sans-serif;
                            font-weight: bold;
                        }

                        .job {
                            margin-top: 7px;
                            font-size: 14px;
                            font-family: Arial, Helvetica, sans-serif;
                        }
                    }

                    .activeFeature {
                        @extend .feature;
                        box-shadow: 0px 4px 20px #00000033;
                    }
                }
            }

            .right {
                padding-left: 10%;

                .title {
                    font-family: 'Nexa';
                    font-weight: 800;
                    font-size: 32px;
                    line-height: 50px;
                }

                .stars {
                    display: flex;
                    justify-content: flex-start;
                    gap: 5px;
                }

                .desc {
                    margin-top: 35px;
                    margin-bottom: 30px;
                    font-weight: 400;
                    font-size: 24px;

                    @media screen and (max-width: 900px) {
                        font-size: 20px;
                    }
                }
            }
        }
    }

    .join {
        max-width: 1060px;
        margin-inline: auto;
        margin-top: 100px;
        margin-bottom: 192px;
        background-image: url('../../assets/img/join_1.png');
        background-size: 100% 100%;
        background-position: center;
        padding: 96px 110px 6px;
        text-align: center;

        @media screen and (max-width: 900px) {
            padding: 96px 28px 60px;
            background-size: auto 100%;
        }

        .content {
            .subtitle {
                color: #0453E5;
                font-family: 'Nexa';
                font-style: normal;
                font-weight: 800;
                font-size: 24px;
                line-height: 80px;
            }

            .title {
                margin-top: -5%;
                font-family: 'Nexa';
                font-style: normal;
                font-weight: 800;
                font-size: 50px;
                line-height: 100%;
            }

            .desc {
                margin-top: 14px;
                font-weight: 400;
                font-size: 24px;
                line-height: 29px;
                text-align: center;

                @media screen and (max-width: 900px) {
                    margin-top: 30px;
                }
            }
        }

        .demoBtn {
            width: fit-content;
            margin-inline: auto;
            margin-top: 17px;
            background-image: url('../../assets/img/menu_bg_1.svg');
            color: white;
            background-size: 100% 100%;
            padding: 37px 67px;
            font-family: 'Nexa';
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 24px;
            z-index: 999;
            cursor: pointer;

            &:hover {
                color: black;
                transform: scale(1.1);
            }

            @media screen and (max-width: 900px) {
                margin-top: 20px;
                padding: 21px 38px;
                font-size: 18px;
                line-height: 18px;
            }
        }
    }
}
