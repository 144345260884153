.footer {
    max-width: 1340px;
    margin-inline: auto;

    @media screen and (max-width: 1200px) {
        padding: 20px;
    }

    .logo {
        display: block;
        margin-inline: auto;
    }

    .desc {
        font-family: 'Nexa';
        font-weight: 200;
        font-size: 22px;
        line-height: 40px;
        text-align: center;

        span {
            color: #ff665a;
        }
    }

    .footerBar {
        margin-top: 40px;
        width: 100%;
        padding: 50px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        background: #F7F9FC;
        border-radius: 20px;

        @media screen and (max-width: 900px) {
            display: block;
            padding: 20px;
        }

        .left {
            text-align: left;

            @media screen and (max-width: 900px) {
                text-align: center;
            }
        }

        .middle {
            width: 100%;
            text-align: center;
            display: flex;
            justify-content: center;
            gap: 18px;

            @media screen and (max-width: 900px) {
                margin-top: 30px;
            }
        }

        .right {
            text-align: right;

            @media screen and (max-width: 900px) {
                text-align: center;
                margin-top: 30px;
            }
        }
    }
}